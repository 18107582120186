
  export default {
    components: {
      JobBoxOpportunities: () =>
        import('@/components/elements/JobBoxOpportunities'),
      JobBoxTopOffers: () => import('@/components/elements/JobBoxTopOffers'),
      JobBoxRecommendations: () =>
        import('@/components/elements/JobBoxRecommendations'),
      JobBoxNewJobs: () => import('@/components/elements/JobBoxNewJobs'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
      filterParams: {
        type: String,
        required: false,
        default: '',
      },
      numJobPostings: {
        type: Number,
        required: false,
        default: 5,
      },
    },
    data() {
      return {
        tab: null,
        tabs: [
          {
            name: 'top_offers',
            visibility: this.section.show_top_offers,
            title: this.$t('job_box.top_offers'),
          },
          {
            name: 'opportunities',
            visibility: this.section.show_opportunities,
            title: this.$t('job_box.opportunities'),
          },
          {
            name: 'recommendations',
            visibility:
              this.section.show_recommendations && this.$auth.loggedIn,
            title: this.$t('job_box.recommendations'),
          },
          {
            name: 'new_jobs',
            visibility: this.section.show_new_jobs,
            title: this.$t('job_box.new_jobs'),
          },
        ],
        visibleTabs: [],
      };
    },
    mounted() {
      let isActiveTabExists = false;
      this.tabs.map((tab) => {
        if (tab.visibility === true) {
          this.visibleTabs.push(tab);
          if (tab.name === this.section.active_tab) {
            isActiveTabExists = true;
          }
        }
      });

      if (isActiveTabExists === true) {
        this.tab = this.visibleTabs.findIndex(
          (element) => element.name === this.section.active_tab
        );
      } else {
        const isTopOffer = this.visibleTabs.find(
          (element) => element.name === 'top_offers'
        );
        const randomTab =
          isTopOffer && this.section.active_tab != 'random'
            ? isTopOffer.name
            : this.visibleTabs[
                Math.floor(Math.random() * this.visibleTabs.length)
              ].name;
        this.tab = this.visibleTabs.findIndex(
          (element) => element.name === randomTab
        );
      }
    },
  };
